<template>
    <auth-layout>
        <img src="shield-check.png" class="image-auth"> 
        <h2 class="sandi-disimpan">Kata Sandi Disimpan</h2>
        <span class="welcome-note" style="margin-bottom: 20px !important;">
          Silahkan melakukan login kembali ke akun Anda.
        </span>
        <button
            class="btn vm-btn-submit btn-block btn-flat"
            style="margin-top: 20px;"
            :disabled="loading"
            @click="$router.replace('login')"
            >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            {{ loading ? "..." : "Kembali ke Login" }}
            </button>
    </auth-layout>
</template>

<script>
import AuthLayout from "@components/layouts/Auth.vue";

export default {
    components: {
        AuthLayout
    }
}
</script>

<style lang="sass">
.sandi-disimpan
    margin: 50px 0 0 0px
</style>