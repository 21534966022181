<template>
  <div>
    <!-- <div class="card vm-card">
      <div class="card-body"> -->
    <div>
      <!-- <div class="col-md-12"> -->
      <div class="login-header">

      </div>
      <div class="login-logo-container">
        <img src="@assets/img/logo-vending.png" class="login-background" alt="logo-vending" />
      </div>

      <div class="bg-login-container login-background">
        <img class="login-image" src="../../../assets/img/login-image.png">
      </div>
    </div>
    <!-- </div> -->

    <!-- Form -->
    <!-- <div class="col-md-6 login-form-container"> -->
    <div class="login-back-container" v-if="backToLogin">
      <router-link :to="{ name: 'login' }" style="color: #757575;"><i class="fas fa-chevron-left mr-3 mt-5"></i>Kembali ke Login</router-link>
      <div class="row">
        <div class="col">
          <h2 class="forget-password">Lupa kata sandi Anda?</h2>
          <span class="forget-password-note">
            Isi email Anda di bawah, dan kami akan kirim kode pengaktifkan kembali.
          </span>
        </div>
      </div>
    </div>

    <div class="login-back-container" v-if="successSentEmail">
      <router-link :to="{ name: 'login' }" style="color: #757575;"><i class="fas fa-chevron-left mr-3 mt-5"></i>Kembali ke Login</router-link>
      <div class="row">
        <div class="col">
          <img class="image-auth" src="Subtract.png">
          <h2 class="forget-password">Email telah dikirim</h2>
          <span class="forget-password-note">
            Mohon cek email Anda untuk melanjutkan proses aktivasi kembali.
          </span>
        </div>
      </div>
    </div>

    <div class="mx-3">
      <slot></slot>
    </div>

    <div class="login-footer">
      <span style="copyright">Copyright ©2023 PT Dharma Precision Tools. Hak Cipta Dilindungi.</span>
    </div>
  </div>
</template>

<script>
import "@assets/css/auth.css";
const pjson = require("../../../../package.json");
export default {
  data: () => ({
    app_version: pjson.version,
  }),
  props: {
    backToLogin: Boolean,
    successSentEmail: Boolean
  },
  mounted() {
    if (Api.getToken()) {
      this.$router.replace({ name: "Dashboard" });
    }
    
    $("body").attr("class", "hold-transition vm-login-page text-sm");
    $("body,html").removeAttr("style");
    new adminlte.Layout("body", {});
  },
};
</script>
